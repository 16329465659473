import {
  getPaginated,
  PaginatedRequest,
  PaginatedResponse,
} from "@/application/api/getPaginated";
import { AxiosResponse } from "axios";
import mapIndexResponseErrors from "@/application/util/mapIndexResponseErrors";

export interface ReceiptIndexItem {
  id: number;
  productCode: string;
  dimProduct: {
    productName?: string;
  };
  receiptNumber: string;
  receiptDate: string;
  stockStatus: string;
  lotNumber: null | string;
  bestBeforeDate: null | string;
  announcedQuantity: number;
  receivedQuantity: number;
  tradeUnit: string;
}

export const clientReceiptIndex = async (
  data: PaginatedRequest,
  clientId: number
): Promise<AxiosResponse<PaginatedResponse<ReceiptIndexItem[]>>> => {
  const response = await getPaginated(`client/${clientId}/delivery`, data);
  response.data.data = mapIndexResponseErrors(response.data.data);

  return response;
};


import Vue, { VueConstructor } from "vue";
import KCrudTable, {
  CrudTableHeader,
} from "@/modules/crudTable/components/KCrudTable.vue";
import { ExportType, PaginatedRequest } from "@/application/api/getPaginated";
import { mapGetters } from "vuex";
import RequiredClientDialog from "@/modules/client/components/RequiredClientDialog.vue";
import {
  clientReceiptIndex,
  ReceiptIndexItem,
} from "@/modules/delivery/api/receipt";
import ExportButton from "@/components/ExportButton.vue";
import ReceiptOverviewFilter from "@/modules/delivery/components/ReceiptOverviewFilter.vue";

interface ComponentData {
  headers: CrudTableHeader[];
  selected: Partial<ReceiptIndexItem> & { clientId: number; id: number }[];
  exports: ExportType[];
  filterComponent: VueConstructor;
  receiptIndexData: ReceiptIndexItem[];
}

export default Vue.extend({
  name: "ReceiptTable",
  components: {
    ExportButton,
    RequiredClientDialog,
    KCrudTable,
  },
  data: (): ComponentData => ({
    headers: [
      { value: "receiptNumber", sortable: false },
      { value: "receiptDate", sortable: true },
      { value: "productCode", sortable: true },
      { value: "dimProduct.productName", sortable: false },
      { value: "stockStatus", sortable: false },
      { value: "lotNumber", sortable: true },
      { value: "bestBeforeDate", sortable: true },
      { value: "anouncedQuantity", sortable: false },
      { value: "receivedQuantity", sortable: false },
      { value: "tradeUnit", sortable: false },
    ],
    selected: [],
    exports: [],
    filterComponent: ReceiptOverviewFilter,
    receiptIndexData: [],
  }),
  computed: {
    ...mapGetters("authorisation", ["client", "isClient"]),
    ...mapGetters("crudTable", ["getRouteSetting"]),
  },
  watch: {
    client: {
      handler() {
        this.refreshTable();
      },
      deep: true,
    },
  },
  methods: {
    async receiptIndex(data: PaginatedRequest) {
      if (!this.client?.id) throw "Client id is not set";
      const response = await clientReceiptIndex(data, this.client?.id);
      this.receiptIndexData = response.data.data;
      this.exports = response.data.export ?? [];
      return response;
    },
    refreshTable(): void {
      (this.$refs.table as any).getData();
    },
  },
});
